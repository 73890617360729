<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/reservations">Reservierungen</router-link></li>
              <li>Reservierung #{{ reservation.id }}</li>
            </ul>
            <div class="header-actions">
              <p style="display:inline-block;margin-right:10px"><a @click="duplicate_reservation" class="button button-orange button-small">Reservierung duplizieren ›</a></p>
              <p style="display:inline-block;"><router-link to="/admin/reservations" class="button button-grey button-small">Alle Reservierungen ›</router-link></p>
            </div>
          </header>

          <div style="margin-top:20px">

            <div class="well">
              <h3>Übersicht</h3>
              <p><strong>Reservierung erstellt am:</strong> {{ reservation.created_at_formatted }}</p>
              <p><strong>Abholung:</strong> {{ reservation.pickup_timestamp }}</p>
              <p><strong>Rückgabe:</strong> {{ reservation.return_timestamp }}</p>

              <hr>

              <form @submit="update_reservation" class="form" method="post">

                <div class="form-wrap select-wrap">
                  <label for="status">Status</label>
                  <select v-model="reservation.status" id="status">
                    <option value="booked">Gebucht</option>
                    <option value="paid">Bezahlt</option>
                    <option value="canceled">Storniert</option>
                    <option value="ended">Beendet</option>
                  </select>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="row row-gutter-20">
                      <div class="col-12">
                        <div class="form-wrap select-wrap">
                          <label for="pickup_date">Abholung Datum</label>
                          <v-date-picker v-model="reservation.pickup_date" :min-date='this.reservation.pickup_date' locale="de">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                class="form-input"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </v-date-picker>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-wrap select-wrap">
                          <label for="pickup_time">Abholung Uhrzeit</label>
                          <select v-model="reservation.pickup_time" id="pickup_time">
                            <option value="00">00:00 Uhr</option>
                            <option value="01">01:00 Uhr</option>
                            <option value="02">02:00 Uhr</option>
                            <option value="03">03:00 Uhr</option>
                            <option value="04">04:00 Uhr</option>
                            <option value="05">05:00 Uhr</option>
                            <option value="06">06:00 Uhr</option>
                            <option value="07">07:00 Uhr</option>
                            <option value="08">08:00 Uhr</option>
                            <option value="09">09:00 Uhr</option>
                            <option value="10">10:00 Uhr</option>
                            <option value="11">11:00 Uhr</option>
                            <option value="12">12:00 Uhr</option>
                            <option value="13">13:00 Uhr</option>
                            <option value="14">14:00 Uhr</option>
                            <option value="15">15:00 Uhr</option>
                            <option value="16">16:00 Uhr</option>
                            <option value="17">17:00 Uhr</option>
                            <option value="18">18:00 Uhr</option>
                            <option value="19">19:00 Uhr</option>
                            <option value="20">20:00 Uhr</option>
                            <option value="21">21:00 Uhr</option>
                            <option value="22">22:00 Uhr</option>
                            <option value="23">23:00 Uhr</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row row-gutter-20">
                      <div class="col-12">
                        <div class="form-wrap select-wrap">
                          <label for="return_date">Rückgabe Datum</label>
                          <v-date-picker v-model="reservation.return_date" :min-date='this.reservation.pickup_date' locale="de">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                class="form-input"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </v-date-picker>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-wrap select-wrap">
                          <label for="return_time">Rückgabe Uhrzeit</label>
                          <select v-model="reservation.return_time" id="return_time">
                            <option value="00">00:00 Uhr</option>
                            <option value="01">01:00 Uhr</option>
                            <option value="02">02:00 Uhr</option>
                            <option value="03">03:00 Uhr</option>
                            <option value="04">04:00 Uhr</option>
                            <option value="05">05:00 Uhr</option>
                            <option value="06">06:00 Uhr</option>
                            <option value="07">07:00 Uhr</option>
                            <option value="08">08:00 Uhr</option>
                            <option value="09">09:00 Uhr</option>
                            <option value="10">10:00 Uhr</option>
                            <option value="11">11:00 Uhr</option>
                            <option value="12">12:00 Uhr</option>
                            <option value="13">13:00 Uhr</option>
                            <option value="14">14:00 Uhr</option>
                            <option value="15">15:00 Uhr</option>
                            <option value="16">16:00 Uhr</option>
                            <option value="17">17:00 Uhr</option>
                            <option value="18">18:00 Uhr</option>
                            <option value="19">19:00 Uhr</option>
                            <option value="20">20:00 Uhr</option>
                            <option value="21">21:00 Uhr</option>
                            <option value="22">22:00 Uhr</option>
                            <option value="23">23:00 Uhr</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-wrap select-wrap">
                  <label for="vehicle_categories">Fahrzeugkategorie</label>
                  <select v-model="reservation.category_id" @change="vehicle_category_change($event)" id="vehicle_categories">
                    <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
                  </select>
                </div>

                <div class="form-wrap select-wrap">
                  <label for="vehicle_categories">Fahrzeug</label>
                  <select v-model="reservation.vehicle_id" id="vehicles">
                    <option v-for="vehicle in vehicles" v-bind:key="vehicle.id" v-bind:value="vehicle.id">{{ vehicle.license_plate }}</option>
                  </select>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap select-wrap">
                      <label for="pickup_location">Abholung in</label>
                      <select v-model="reservation.pickup_location_id" id="pickup_location">
                        <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">
                          {{ location.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap select-wrap">
                      <label for="return_location">Rückgabe in</label>
                      <select v-model="reservation.return_location_id" id="return_location">
                        <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">
                          {{ location.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="pickup_km">KM bei Abholung</label>
                      <input v-model="reservation.pickup_km" id="pickup_km" class="form-input">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="return_km">KM bei Rückgabe</label>
                      <input v-model="reservation.return_km" id="return_km" class="form-input">
                    </div>
                  </div>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Änderungen speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Fahrzeugrückgabe</h3>

              <div v-if="reservation.status == 'ended'">
                <div class="alert-success">
                  Die Reservierung wurde erfolgreich beendet.
                </div>
              </div>
              <div v-else>

                <div v-if="reservation.pickup_km">

                  <div class="form-wrap">
                    <label for="return_km">KM bei Rückgabe</label>
                    <input v-model="reservation.return_km" id="return_km" class="form-input">
                  </div>

                  <div class="row row-gutter-20">
                    <div class="col-12">
                      <p>
                        <strong style="display: block;margin-bottom: 0.5rem;font-weight: 600;color: #6E6E6E;font-size: 14px;">KM gefahren:</strong>
                        <span v-if="reservation.return_km">{{ reservation.return_km-reservation.pickup_km }} km</span>
                        <span v-else>Bitte KM bei Rückgabe angeben.</span>
                      </p>
                    </div>
                    <div class="col-12">
                      <p>
                        <strong style="display: block;margin-bottom: 0.5rem;font-weight: 600;color: #6E6E6E;font-size: 14px;">Inklusivkilometer:</strong>
                        {{ km_included }} km
                      </p>
                    </div>
                  </div>

                  <div v-if="reservation.return_km">
                    <div v-if="(reservation.return_km-reservation.pickup_km) > km_included">
                      <div class="alert-info">
                        Die Inklusivkilometer wurden überschritten!
                      </div>

                      <h3>Zusatzrechnung</h3>

                      <p>Die Reservierung wurde um {{ (reservation.return_km-reservation.pickup_km) - km_included }} km überschritten. {{ (reservation.return_km-reservation.pickup_km) - km_included }} km * 0,35 EUR pro km = <strong>{{ parseFloat(((reservation.return_km-reservation.pickup_km) - km_included)*0.35).toFixed(2) }} EUR</strong></p>

                      <div class="form-wrap">
                        <label for="km_amount">Betrag</label>
                        <input v-model="km_amount" id="km_amount" class="form-input">
                      </div>

                      <div class="row row-gutter-20">
                        <div class="col-12">
                          <p style="margin-top:0;margin-bottom:0">
                            <a @click="complete_reservation" class="button button-orange button-small button-100">Ohne Zusatzrechnung abschließen ›</a>
                          </p>
                        </div>
                        <div class="col-12">
                          <p style="margin-top:0;margin-bottom:0">
                            <a @click="complete_reservation_with_invoice" class="button button-orange button-small button-100">Zusatzrechnung generieren und abschließen ›</a>
                          </p>
                        </div>
                      </div>

                    </div>
                    <div v-else>
                      <p style="margin-top:0;margin-bottom:0">
                        <input type="submit" value="Reservierung abschließen ›" class="button button-orange button-small">
                      </p>
                    </div>
                  </div>

                </div>
                <div v-else>

                  <div class="alert-danger">
                    Bitte zuerst angeben, wie viele KM das Fahrzeug bei Abholung hatte.
                  </div>

                </div>
              </div>

            </div>

            <div class="well">
              <h3>Reserviert von</h3>

              <form @submit="update_reservation_data" class="form" method="post">

                <div class="form-wrap">
                  <label for="company">Unternehmen</label>
                  <input v-model="reservation.company" id="company" class="form-input">
                </div>

                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="first_name">Vorname</label>
                      <input v-model="reservation.first_name" id="first_name" class="form-input">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="last_name">Nachname</label>
                      <input v-model="reservation.last_name" id="last_name" class="form-input">
                    </div>
                  </div>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-16">
                    <div class="form-wrap">
                      <label for="street_name">Straße</label>
                      <input v-model="reservation.street_name" class="form-input" type="text" name="street_name" id="street_name">
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-wrap">
                      <label for="street_number">Hausnummer</label>
                      <input v-model="reservation.street_number" class="form-input" type="text" name="street_number" id="street_number">
                    </div>
                  </div>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-8">
                    <div class="form-wrap">
                      <label for="zip_code">Postleitzahl</label>
                      <input v-model="reservation.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                    </div>
                  </div>
                  <div class="col-16">
                    <div class="form-wrap">
                      <label for="city">Stadt</label>
                      <input v-model="reservation.city" class="form-input" type="text" name="city" id="city">
                    </div>
                  </div>
                </div>

                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="email">E-Mail</label>
                      {{ reservation.email }}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="phone_number">Telefonnummer</label>
                      {{ reservation.phone_number }}
                    </div>
                  </div>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Änderungen speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Bezahlmethode</h3>

              <form @submit="update_payment_method" class="form" method="post">

                <div class="form-wrap select-wrap">
                  <label for="payment_option">Bezahlmethode auswählen</label>
                  <select v-model="reservation.payment_option" id="payment_option">
                    <option value="sepa">SEPA Lastschrift</option>
                    <option value="card">Kreditkarte</option>
                    <option value="sofort">SOFORT</option>
                    <option value="cash">Bar</option>
                    <option value="ec_card">EC Karte</option>
                    <option value="bank_transfer">Überweisung</option>
                  </select>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Bezahlmethode speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Kaution</h3>

              <form @submit="update_deposit" class="form" method="post">

                <div class="form-wrap">
                  <label for="deposit">Kaution in EUR</label>
                  <input v-model="reservation.deposit" id="deposit" class="form-input">
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Kaution speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Preis für Extra KM</h3>

              <form @submit="update_extra_km" class="form" method="post">

                <div class="form-wrap">
                  <label for="extra_km">Extra KM in EUR (Punkt als Trennzeichen)</label>
                  <input v-model="reservation.extra_km" id="extra_km" class="form-input">
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Preis für Extra KM speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well" style="position:relative">
              <h3>Rechnungen</h3>
              <div style="margin-bottom:0;position:absolute;right:30px;top:20px"><a @click="create_invoice" class="button button-orange button-small button-100"><span class="material-icons">receipt</span><span>Rechnung erstellen ›</span></a></div>

              <div v-if="reservation.invoices && reservation.invoices.length > 0" style="margin-top:30px;margin-bottom:10px">
                <div v-for="invoice in reservation.invoices" v-bind:key="invoice.uuid" style="margin-bottom:10px">
                  <p>Rechnung #{{ invoice.number }} ({{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(invoice.sum_gross)}})</p>
                  <div v-if="invoice.canceled" class="row row-gutter-20">
                    <div class="col-8">
                      <router-link :to="'/invoices/'+invoice.uuid" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">receipt</span><span>Rechnung Web ›</span></router-link>
                    </div>
                    <div class="col-8">
                      <a :href="'https://api.intime.temp.nodion.app/v1/invoices/'+invoice.uuid+'/pdf?canceled=true'" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">receipt</span><span>Rechnung PDF ›</span></a>
                    </div>
                    <div class="col-8">
                      <a :href="'https://api.intime.temp.nodion.app/v1/invoices/'+invoice.uuid+'/pdf'" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">receipt</span><span>Rechnung Original PDF ›</span></a>
                    </div>
                  </div>
                  <div v-else class="row row-gutter-20">
                    <div class="col-12">
                      <router-link :to="'/invoices/'+invoice.uuid" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">receipt</span><span>Rechnung Web ›</span></router-link>
                    </div>
                    <div class="col-12">
                      <a :href="'https://api.intime.temp.nodion.app/v1/invoices/'+invoice.uuid+'/pdf'" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">receipt</span><span>Rechnung PDF ›</span></a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="alert alert-info" style="margin-bottom:0">Aktuell noch keine Rechnung vorhanden.</div>
              </div>

            </div>

            <div class="well">
              <h3>Dokumente</h3>

              <div style="margin-bottom:10px">
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <router-link :to="'/contracts/'+reservation.uuid" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">note</span><span>Mietvertrag Web ›</span></router-link>
                  </div>
                  <div class="col-12">
                    <a :href="'https://api.intime.temp.nodion.app/v1/reservations/'+reservation.uuid+'/contract_pdf'" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">note</span><span>Mietvertrag PDF ›</span></a>
                  </div>
                </div>
              </div>

              <div style="margin-bottom:10px">
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <router-link :to="'/handovers/'+reservation.uuid" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">note</span><span>Übergabeprotokoll Web ›</span></router-link>
                  </div>
                  <div class="col-12">
                    <a :href="'https://api.intime.temp.nodion.app/v1/reservations/'+reservation.uuid+'/handover_pdf'" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">note</span><span>Übergabeprotokoll PDF ›</span></a>
                  </div>
                </div>
              </div>

              <div style="margin-bottom:10px">
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <router-link :to="'/returns/'+reservation.uuid" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">note</span><span>Rückgabeprotokoll Web ›</span></router-link>
                  </div>
                  <div class="col-12">
                    <a :href="'https://api.intime.temp.nodion.app/v1/reservations/'+reservation.uuid+'/return_pdf'" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">note</span><span>Rückgabeprotokoll PDF ›</span></a>
                  </div>
                </div>
              </div>

              <div v-if="reservation.invoices && reservation.invoices.length > 1">

                <hr style="margin:35px 0 25px 0">

                <h3>Zusatzrechnung</h3>

                <div style="margin-bottom:10px">
                  <div class="row row-gutter-20">
                    <div class="col-12">
                      <router-link :to="'/invoices/'+reservation.invoices[1].uuid" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">receipt</span><span>Rechnung {{ parseFloat(reservation.invoices[1].sum_gross).toFixed(2) }}€ Web ›</span></router-link>
                    </div>
                    <div class="col-12">
                      <a :href="'https://api.intime.temp.nodion.app/v1/invoices/'+reservation.invoices[1].uuid+'/pdf'" class="button button-orange button-small button-100" target="_blank"><span class="material-icons">receipt</span><span>Rechnung {{ parseFloat(reservation.invoices[1].sum_gross).toFixed(2) }}€ PDF ›</span></a>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="well">
              <h3>E-Mail Versand</h3>
              <div v-if="send_email_loading">
                <div class="loading-wrap" style="padding:0">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <p style="margin-bottom:0"><a @click="send_email_confirmation" class="button button-orange button-small button-100"><span class="material-icons">email</span><span>Reservierungsinfos jetzt senden ›</span></a></p>
                  </div>
                  <div class="col-12">
                    <div v-if="reservation.invoices">
                      <p v-if="reservation.invoices.length > 0" style="margin-bottom:0"><a @click="send_email_invoice" class="button button-orange button-small button-100"><span class="material-icons">email</span><span>Rechnung jetzt senden ›</span></a></p>
                      <p v-if="reservation.invoices.length == 0" style="margin-bottom:0"><span class="button button-grey button-small button-100" style="cursor:not-allowed"><span class="material-icons">email</span><span>Rechnung jetzt senden ›</span></span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="well">
              <h3>Notizen</h3>

              <form @submit="update_reservation" class="form" method="post">

                <div class="form-wrap">
                  <textarea v-model="reservation.description" placeholder="Notizen zu dieser Reservierung..." class="form-input" rows="5"></textarea>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Änderungen speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Rechnungsinfos</h3>

              <form @submit="update_invoice_details" class="form" method="post">

                <div class="form-wrap">
                  <textarea v-model="reservation.invoice_details" placeholder="Ergänzungen zur Rechnung..." class="form-input" rows="5"></textarea>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Änderungen speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Kostenübersicht</h3>

              <table class="table" style="border: 1px solid #dee2e6;margin-bottom:40px">
                <thead>
                  <tr>
                    <th width="50%">Beschreibung</th>
                    <th width="50%" style="text-align:right">Kosten</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in reservation.res_items" v-bind:key="item.name">
                    <td>{{ item.name }}</td>
                    <td style="text-align:right">{{ parseFloat(item.price).toFixed(2) }} €</td>
                  </tr>

                  <tr style="background:#F6F9FD;color:#495057;border-top:3px solid rgb(222, 226, 230)">
                    <td><strong>Gesamtbetrag (Netto)</strong></td>
                    <td style="text-align:right">{{ parseFloat(reservation.sum_net).toFixed(2) }} €</td>
                  </tr>
                  <tr style="background:#F6F9FD;color:#495057">
                    <td><strong>+ {{ reservation.tax_rate }}% MwSt</strong></td>
                    <td style="text-align:right">{{ parseFloat(reservation.sum_tax).toFixed(2) }} €</td>
                  </tr>
                  <tr style="background:#444;color:#fff">
                    <td><strong>GESAMTBETRAG</strong></td>
                    <td style="text-align:right"><strong>{{ parseFloat(reservation.sum_gross).toFixed(2) }} €</strong></td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>

        </div>
      </div>


    </div>

  </div>
</template>


<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'
import moment from 'moment';

export default {
  name: 'admin_reservation',
  components: {
    AdminNav
  },
  data () {
    return {
      reservation: {},
      locations: [],
      categories: [],
      vehicles: [],
      send_email_loading: false,
      kilometer: "",
      km_amount: 0.0,
      days: 0
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.categories = response.data.vehicle_categories;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.reservation = response.data.reservation;
        this.reservation.pickup_date = new Date(moment(this.reservation.pickup_date).format("YYYY, MM, DD"));
        this.reservation.pickup_time = moment(this.reservation.pickup_time).subtract(1, 'hours').format("HH");
        this.reservation.return_date = new Date(moment(this.reservation.return_date).format("YYYY, MM, DD"));
        this.reservation.return_time = moment(this.reservation.return_time).subtract(1, 'hours').format("HH");

        this.reservation.created_at_formatted = moment(this.reservation.created_at).format("DD.MM.YYYY HH:MM");
        this.reservation.pickup_date_formatted = moment(this.reservation.pickup_date).format("DD.MM.YYYY");
        this.reservation.return_date_formatted = moment(this.reservation.return_date).format("DD.MM.YYYY");
        this.reservation.pickup_time_formatted = moment(this.reservation.pickup_time).format("HH:mm");
        this.reservation.return_time_formatted = moment(this.reservation.return_time).format("HH:mm");

        this.reservation.res_items.forEach(item => {
          if (item.name.includes("Kilometer")) {
            this.kilometer = item.name;
          }
        })

        this.km_included = 0;

        if (parseInt(this.kilometer.replace(/\D/g,'')) != 100) {
          this.km_included = 100*this.reservation.calc_duration;
        } else {
          this.km_included = parseInt(this.kilometer.replace(/\D/g,''))+(100*this.reservation.calc_duration);
        }

        this.get_vehicle_category(response.data.reservation.category_id);

      })
      .catch(error => {
        console.log(error);
      })

    },
    vehicle_category_change(event) {
      this.get_vehicle_category(event.target.value);
    },
    get_vehicle_category(id) {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles?category_id='+id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.vehicles = response.data.vehicles;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_reservation: function (e) {
      e.preventDefault();

      this.reservation.pickup_date = moment(this.reservation.pickup_date).format("DD.MM.YYYY");
      this.reservation.pickup_time = this.reservation.pickup_time+":00";
      this.reservation.return_date = moment(this.reservation.return_date).format("DD.MM.YYYY");
      this.reservation.return_time = this.reservation.return_time+":00";

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, this.reservation, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })

    },
    complete_reservation() {

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, {
        pickup_km: this.reservation.pickup_km,
        return_km: this.reservation.return_km,
        status: 'ended'
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })

    },
    complete_reservation_with_invoice() {

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, {
        pickup_km: this.reservation.pickup_km,
        return_km: this.reservation.return_km,
        status: 'ended'
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);

        var items = [{
          "name": "Überziehung Kilometer",
          "price": this.km_amount
        }]

        axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/invoices', {
          'reservation_id': this.reservation.id,
          'date': new Date(),
          'company': this.reservation.company,
          'first_name': this.reservation.first_name,
          'last_name': this.reservation.last_name,
          'street_name': this.reservation.street_name,
          'street_number': this.reservation.street_number,
          'zip_code': this.reservation.zip_code,
          'city': this.reservation.city,
          'tax_rate': 0,
          'sum_net': parseFloat(this.km_amount/1.19).toFixed(2),
          'sum_tax': (parseFloat(this.km_amount).toFixed(2))-(parseFloat(this.km_amount/1.19).toFixed(2)),
          'sum_gross': parseFloat(this.km_amount).toFixed(2),
          'items': items
        }, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          console.log(response);
          this.$notify({
            group: 'alert',
            type: 'success',
            title: 'Erfolgreich gespeichert.'
          });
          this.get_data();
        })
        .catch(error => {
          error.response.data.errors.forEach(val => {
            this.$notify({
              group: 'alert',
              type: 'error',
              title: 'Fehler',
              text: val
            });
          })
        })

        //this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })

    },
    update_reservation_data: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, {
        'company': this.reservation.company,
        'first_name': this.reservation.first_name,
        'last_name': this.reservation.last_name,
        'street_name': this.reservation.street_name,
        'street_number': this.reservation.street_number,
        'zip_code': this.reservation.zip_code,
        'city': this.reservation.city,
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
    },
    update_deposit: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, {
        'deposit': this.reservation.deposit,
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
    },
    update_extra_km: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, {
        'extra_km': this.reservation.extra_km,
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
    },
    create_invoice() {

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/invoices', {
        'reservation_id': this.reservation.id,
        'date': new Date(),
        'company': this.reservation.company,
        'first_name': this.reservation.first_name,
        'last_name': this.reservation.last_name,
        'street_name': this.reservation.street_name,
        'street_number': this.reservation.street_number,
        'zip_code': this.reservation.zip_code,
        'city': this.reservation.city,
        'tax_rate': this.reservation.tax_rate,
        'sum_net': this.reservation.sum_net,
        'sum_tax': this.reservation.sum_tax,
        'sum_gross': this.reservation.sum_gross,
        'items': this.reservation.res_items
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })

    },
    update_invoice_details: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, {
        'invoice_details': this.reservation.invoice_details
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })
    },
    send_email_confirmation() {
      this.send_email_loading = true;

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id+'/email_confirmation', {}, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'E-Mail erfolgreich verschickt.'
        });
        this.send_email_loading = false;
      })
    },
    send_email_invoice() {
      this.send_email_loading = true;

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id+'/email_invoice', {}, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'E-Mail erfolgreich verschickt.'
        });
        this.send_email_loading = false;
      })
    },
    update_payment_method: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+this.$route.params.id, {
        'payment_option': this.reservation.payment_option
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
    },
    duplicate_reservation() {
      var pickup_date = moment(this.reservation.pickup_date).format("X");
      var return_date = moment(this.reservation.return_date).format("X");
      this.$router.push('/admin/reservations/new?pickup_date_unix='+pickup_date+'&pickup_time='+this.reservation.pickup_time+'&return_date_unix='+return_date+'&return_time='+this.reservation.return_time+'&pickup_location_id='+this.reservation.pickup_location_id+'&return_location_id='+this.reservation.return_location_id+'&category_id='+this.reservation.category_id+'&user_id='+this.reservation.user_id+'&company='+this.reservation.company+'&first_name='+this.reservation.first_name+'&last_name='+this.reservation.last_name+'&street_name='+this.reservation.street_name+'&street_number='+this.reservation.street_number+'&zip_code='+this.reservation.zip_code+'&city='+this.reservation.city+'&birth_date='+this.reservation.birth_date+'&email='+this.reservation.email+'&phone_number='+this.reservation.phone_number+'&vehicle_id='+this.reservation.vehicle_id);
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
